import { MDBSwitch } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

import ToggleSwitch from 'components/common/ToggleSwitch';
import React, { useState, useEffect } from 'react';

interface Props {
    label: string;
    name: string;
    checked?: Boolean;
    id: string;
    children?: any;
    inline?: boolean;
    className?: string;
    toggleSwitchHandler?: (id: string) => void;
}

const SwitchSection = (props: Props) => {
    const {
        label,
        checked,
        id,
        name,
        toggleSwitchHandler,    
    }: Props = props;

    const { t, i18n } = useTranslation(['trans']);

    const savedLockMovement = localStorage.getItem('lockMovement') === 'true';
    const [lockMovement, setLockMovement] = useState(savedLockMovement || checked || false);

    useEffect(() => {
        localStorage.setItem('lockMovement', String(lockMovement));
    }, [lockMovement]);

    const toggleLockMovementHandler = () => {
        const newLockMovement = !lockMovement;
        setLockMovement(newLockMovement);

        if (toggleSwitchHandler) {
            toggleSwitchHandler(name);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
            <ToggleSwitch
            isSelected={Boolean(lockMovement)}
            handleToggle={toggleLockMovementHandler}
            />
            <div style={{ marginLeft: '10px' }}>{lockMovement ? t("잠금") : "잠금 해지"}</div>{/* <div>{t(label)}</div> */}
            </div>
{/*             <div>
            <MDBSwitch
                    id={id}
                    inline={true}
                    label={""}
                    name={name}
                    onChange={() => toggleSwitchHandler(name)}
                    // defaultChecked={checked ? true : false}
                    checked={checked ? true : false}
                />
            </div> */}
        </div>
    );
};

export default SwitchSection;
