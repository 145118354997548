import { DeviceParams, Traits, TraitsResult } from 'api/device';
import { MDBCard, MDBCardFooter, MDBCardTitle } from 'mdb-react-ui-kit';
import { mollier } from 'mollier';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { findTrait } from 'utils';
import IconBtn from '../iconBtn';

export interface ThermometerFragProps {
    device?: DeviceParams;
    deviceTraits?: TraitsResult;
    toggleSettingsModalHandler?: (event?) => void;
    deviceButtonClick?: (event?) => void;
    hover?: boolean;
}

const ThermometerFrag = (props: ThermometerFragProps) => {
    const { toggleSettingsModalHandler, hover, device, deviceButtonClick } =
        props;

    const humidity: Traits = findTrait(device?.traits, 'humidity');
    const temperature: Traits = findTrait(device?.traits, 'temperature');
    const temperatureUnit = temperature?.unit.includes('°')
        ? temperature?.unit
        : '°' + temperature?.unit;

    const mollierResult = mollier(
        temperature?.currentValue,
        humidity?.currentValue
    );

    return (
        <MDBCard className="thermometer-frag border" style={{ width: "300px" }}>
            {/** 메인아이콘 */}
            <div className="text-center my-3">
                <i className="fas fa-temperature-high icon-card-logo text-danger"></i>
            </div>

            {/** 장치 이름, 시리얼 */}
            <div className="text-center">
                <MDBCardTitle className="m-0">{device?.nickname}</MDBCardTitle>
                <small>serial: {device?.deviceInfo.serial}</small>
            </div>

            {/** traits */}
            <div className="d-flex justify-content-evenly align-items-center text-center">
                <div className="p-2">
                    <small>
                        <strong>temp</strong>
                    </small>
                    <h2 className="text-primary">
                        <strong>{(temperature?.currentValue + temperatureUnit).toString()}</strong>
                    </h2>
                </div>
                <div className="p-2">
                    <small>
                        <strong>humidity</strong>
                    </small>
                    <h2 className="text-warning">
                        <strong>{(humidity?.currentValue + humidity?.unit).toString()}</strong>
                    </h2>
                </div>
            </div>

            {/** 하단 버튼 그룹 */}
            <MDBCardFooter className="justify-content-evenly p-0">
                <NavLink to="/dashboard/alarms">
                    <IconBtn onClick={deviceButtonClick} type="alarm" size="lg"></IconBtn>
                </NavLink>
                <NavLink to="/dashboard/reports">
                    <IconBtn onClick={deviceButtonClick} type="report" size="lg"></IconBtn>
                </NavLink>
                <IconBtn size="lg" onClick={toggleSettingsModalHandler} type="settings"></IconBtn>
            </MDBCardFooter>
        </MDBCard>
    );
};

export default React.memo(ThermometerFrag);
