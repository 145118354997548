import styled from '@emotion/styled';
import {
    DeviceParams,
    queryDevices,
    queryDeviceSubscribe,
    queryGetDevice,
    queryPutPlace,
} from 'api/device';
import { getPlaceDevices } from 'api/place';
import r9iot, { HubDeviceParams, HubParams } from 'api/r9iot';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AddDeviceListDisplay from 'components/display/addDeviceListDisplay';
import DeviceInputSection from 'components/fragment/deviceInputSection';
import AddDeviceModal from 'components/layout/addDeviceModal';
import { addDeviceDisplaySetup } from 'constants/addDeviceSetup';
import { deviceModels } from 'constants/deviceModel';
import {
    updateAddedHub,
    updateAllDeviceList,
    updateDeviceList,
} from 'features/display-slice';
import { MDBBtn } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export interface DeviceinfoDisplayProps {
    [prop: string]: {
        loading?: boolean;
        added?: boolean;
    };
}

export interface AddDeviceContainerProps {
    showModal?: boolean;
    setShowModal?: any;
    toggleModalHandler?: any;
    needTable?: boolean;
    needSubscribe: boolean;
    btnSize?: 'lg' | 'sm';
    maxWidth?: number | string;
}

const StyledButton = styled(MDBBtn)`
    background-color: #292929 !important;
    border: 1px solid #404040 !important;
    color: white !important;
    border-radius: 5px !important; /* 약간 둥근 모서리 */
    padding: 1rem 2rem; /* 적절한 패딩 추가 */
    box-shadow: none !important; /* 그림자 제거 */
    &:hover {
        background-color: #404040 !important; /* 호버 시 약간 밝은 배경색 */
        border-color: #505050 !important; /* 호버 시 테두리색 변경 */
    }
`;

const AddDeviceContainer = (props: AddDeviceContainerProps) => {
    const { needTable, btnSize, maxWidth, needSubscribe,
        toggleModalHandler, showModal, setShowModal
     } = props;

    /* from i18n */
    const { t, i18n } = useTranslation(["trans"]);

    /* states from redux */
    const dispatch = useAppDispatch();
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);
    const currentLocationId = useAppSelector((state) => state.authState.currentLocationId);
    const deviceListFromRedux = useAppSelector((state) => state.display.currentPlaceDeviceList);
    const allDeviceList = useAppSelector((state) => state.display.allDeviceList);

    /* display state */
    //const [showModal, setShowModal] = useState(false);
    const [displayOrder, setDisplayOrder] = useState(0);
    const [deviceModel, setDeviceModel] = useState<string>(null);
    const [deviceInput, setDeviceInput] = useState<string>(null);

    /* functional state */
    const [deviceList, setDeviceList] = useState<DeviceParams[]>(null);
    const [deviceInfoList, setDeviceInfoList] = useState<DeviceParams[]>(null);
    const [deviceInfoDisplayProps, setDeviceInfoDisplayProps] = useState<DeviceinfoDisplayProps>(null);

    const addDeviceOrderContent = addDeviceDisplaySetup[displayOrder];
    const defaultDeviceModel = addDeviceDisplaySetup.find((setup) => setup.deviceModel).deviceModel;

    // set to default state as modal is closed
    useEffect(() => {
        if (!showModal) {
            setDisplayOrder(0);
            setDeviceList(null);
        }
    }, [showModal]);

    //default input states
    useEffect(() => {
        setDeviceInput(null);
        setDeviceModel(defaultDeviceModel);
        setDeviceList(null);
        setDeviceInfoList(null);
    }, [defaultDeviceModel, displayOrder]);

    const fetchAllDeviceList = async () => {
        //const result = await queryDevices(checkedUser?.principalId).catch((error) => console.info(error));
        const result = await queryDevices(checkedUser?.principalId);
        //console.log("queryDevices result", result)

        const deviceList: DeviceParams[] = Object.values(result);
        dispatch(updateAllDeviceList(deviceList));
    };

    useEffect(() => {
        if(checkedUser?.principalId) {
            fetchAllDeviceList();
        }
    }, [checkedUser]);

    //if fetch device info list, change state
    useEffect(() => {
        if (!deviceList) return;

        async function getDeviceInfoList() {
            const deviceInfoList = deviceList.map((info) => {
                return queryGetDevice(checkedUser.principalId, info.uuid);
            });
            const hubDevices: DeviceParams[] = [];
            let newDeviceInfoDisplay: DeviceinfoDisplayProps = {};
            await Promise.all(deviceInfoList)
                .then((result) => {
                    result.forEach((fetched) => {
                        hubDevices.push(fetched.data);

                        /* check if it's already added device in this location */
                        const findDevice = deviceListFromRedux.find((device) => device === fetched.data.uuid);

                        newDeviceInfoDisplay = {
                            ...newDeviceInfoDisplay,
                            [fetched.data.uuid]: {
                                added: !!findDevice ? true : false,
                                loading: false,
                            },
                        };
                    });
                })
                .catch((error) => {
                    toast.error("기기 정보 목록을 가져오지 못했습니다."); /* toast.error("Fail to fetch device info list"); */
                    console.info(error);
                });

            if (!hubDevices) return;
            //console.log("hubDevices", hubDevices);
            //console.log("allDeviceList", allDeviceList);

            const newDeviceInfoList = addSubscribedProperty(hubDevices, allDeviceList);
            setDeviceInfoList(newDeviceInfoList);
            setDeviceInfoDisplayProps({
                ...deviceInfoDisplayProps,
                ...newDeviceInfoDisplay,
            });
        }

        getDeviceInfoList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedUser, deviceList, deviceListFromRedux, allDeviceList]);

    /* handler start*/

    async function subscribe(id, uuid) {
        try {
            const response = await queryDeviceSubscribe(id, uuid);
            await fetchAllDeviceList(); // 반환값을 사용하지 않음
            toast.success("성공적으로 구독되었습니다."); /* toast.success("Subscribed successfully"); */
            return response;
        } catch (error) {
            toast.error("구독 실패"); /* toast.error("Failed to subscribe"); */
            throw error; // 상위 호출자가 오류를 처리할 수 있도록 오류를 재발생시킴
        }
    }

    //display handler
    /*
    function toggleModalHandler() {
        setShowModal(!showModal);
    }
        */

    function moveToPrevHandler() {
        if (displayOrder > 0) {
            // setDisplayOrder(displayOrder - 1);
            setDisplayOrder(0);
        }
    }

    function moveToNextHandler() {
        if (displayOrder < addDeviceDisplaySetup.length - 1) {
            // setDisplayOrder(displayOrder + 1);
            const index = addDeviceDisplaySetup.findIndex(
                (setup) => setup.deviceModel === (deviceModel || defaultDeviceModel)
            );

            setDisplayOrder(index);
        }
    }

    function deviceModelChangeHandler(event) {
        if (event.target.value === "wifi") {
            // 기능 미지원
            toast.error("준비중입니다.");
            setDeviceModel(defaultDeviceModel);
        }
        setDeviceModel(event.target.value);
    }

    function deviceInputChangeHandler(event) {
        setDeviceInput(event.target.value);
    }
    //display handler

    function addSubscribedProperty(hubDevices: DeviceParams[], allDeviceList: DeviceParams[]) {
        hubDevices.forEach((device) => {
            const find = allDeviceList.find((item) => item.uuid === device.uuid);

            if (find) return (device.subscribed = true);

            device.subscribed = false;
        });

        return hubDevices;
    }

    //functional handler
    async function gatewaySearchHandler(event) {
        event.preventDefault();

        if(!showModal) return;
        if (!deviceInput) return;

        async function getHub() {
            const hubResult = await r9iot
                .getHub(checkedUser.principalId, deviceInput)
                .catch((error) => console.info(error));

                if (!hubResult) toast.error("허브를 찾을 수 없습니다."); /* if (!hubResult) toast.error("No Hubs Found"); */

            return (hubResult?.data as HubParams) || null;
        }

        async function subscribeHub(hubData: HubParams) {
            if (!hubData) return;

            const message = "해당 허브를 찾았습니다. 이 허브를 추가하시겠습니까?"; /* const message = "Found corresponding Hub. Do you want to add this Hub?"; */

            const notFoundMessage = "장치를 찾을 수 없습니다."; /* const notFoundMessage = "Device Not Found"; */
            let subscribeAsk: boolean;

            const findDevice = allDeviceList.find((device) => device.uuid === hubData.uuid);

            // If hub is not subscribed, show confirm alarm to ask to subscribe
            /*
            if (!needSubscribe && !findDevice) {
                return toast.error(notFoundMessage);
            } else 
            */
            if (!needSubscribe && findDevice) {
                return getConnectedDevices(hubData);
            } else if (needSubscribe && !findDevice) {
                subscribeAsk = window.confirm(message);
            } else if (needSubscribe && findDevice) {
                //toast.error("This hub is already added");
                return getConnectedDevices(hubData);
            }

            if (!subscribeAsk) return;

            // hub의 친구추가 모드를 확인해서 서버에서 200, 401을 회신한다.
            const subscribeResult = await subscribe(checkedUser.principalId, hubData.uuid);
            if (!subscribeResult) return subscribeResult;

            dispatch(updateAddedHub(hubData));

            //table을 필요로 하지않는 container의 경우 바로 modal이 off 되도록함
            if (!needTable) {
                return setShowModal(false);
            }

            getConnectedDevices(hubData);
        }

        async function getConnectedDevices(hubData: HubParams) {
            await r9iot
                .getHubDevices(checkedUser.principalId, hubData.id) // 검색된 허브에 연결된 장치 목록 - 구독여부와 무관한 조회
                .then((response) => {
                    let hubDevices = [];

                    Object.keys(response?.data).forEach((key: string) => {
                        /*
                        const findSubscribedDevice = allDeviceList.find(
                            (device) => device.uuid === key
                        );

                        if (findSubscribedDevice)
                            hubDevices.push(response.data[key]);
                        */
                        hubDevices.push(response.data[key]);
                    });

                    // setDeviceList(Object.values(response?.data));
                    setDeviceList(hubDevices);
                })
                .catch((error) => {
                    toast.error("Device info load fail");
                    console.info(error);
                });
        }

        // 허브ID를 가지고 허브가 있는지 확인한다.
        const hubData = await getHub();
        // 허브가 있다면 구독할건지 물어보고 구독 진행함.
        if (hubData) {
            return subscribeHub(hubData);
        }
    }

    async function addDeviceClickHandler(uuid: string) {
        setDeviceInfoDisplayProps({
            ...deviceInfoDisplayProps,
            [uuid]: {
                loading: true,
                added: false,
            },
        });

        const result = await queryPutPlace(checkedUser.principalId, uuid, {
            placeUuid: currentLocationId,
        }).catch((error) => {
            toast.error("위치에 장치를 추가하지 못했습니다."); /* toast.error("Fail to add device to location"); */
            console.info(error);
        });

        if (!result) return;

        await currentLocationResult();
        setDeviceInfoDisplayProps({
            ...deviceInfoDisplayProps,
            [uuid]: {
                loading: false,
                added: true,
            },
        });
    }

    /* update current location device to redux state */
    async function currentLocationResult() {
        const result = await getPlaceDevices(checkedUser?.principalId, currentLocationId).catch((error) => {
            console.info("Fail to fetch current location device");
            console.info(error);
        });

        if (!result) return;

        dispatch(updateDeviceList(result));
    }

    /* handler end*/

    let inputSection = (
        <DeviceInputSection
            title={addDeviceOrderContent.title}
            label={addDeviceOrderContent.label}
            type={addDeviceOrderContent.type}
            placeholder={addDeviceOrderContent.placeholder}
            btnText={addDeviceOrderContent.btnText}
            moveToNextHandler={moveToNextHandler}
            deviceSubmit={gatewaySearchHandler}
            deviceInput={deviceInput}
            deviceInputChange={deviceInputChangeHandler}
        />
    );

    if (displayOrder === 0)
        inputSection = (
            <DeviceInputSection
                title={addDeviceOrderContent.title}
                label={addDeviceOrderContent.label}
                type={addDeviceOrderContent.type}
                placeholder={addDeviceOrderContent.placeholder}
                btnText={addDeviceOrderContent.btnText}
                moveToNextHandler={moveToNextHandler}
                valueChanged={deviceModelChangeHandler}
                data={deviceModels}
                value={defaultDeviceModel}
                validValue
            />
        );

    return (
        <>
        {/**
            <StyledButton size={btnSize || "lg"} onClick={toggleModalHandler}>
                {t("장치 추가")}
            </StyledButton>
         */}

            <form onSubmit={gatewaySearchHandler}>
                <AddDeviceModal
                    maxWidth={maxWidth}
                    modalTitle={addDeviceOrderContent.title}
                    activePrev={addDeviceOrderContent.activePrev}
                    btnText={addDeviceOrderContent.btnText}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    toggleModalHandler={toggleModalHandler}
                    moveToPrevHandler={moveToPrevHandler}
                >
                    {inputSection}
                    {addDeviceOrderContent.search && needTable ? (
                        <AddDeviceListDisplay
                            deviceInfoList={deviceInfoList}
                            addClick={addDeviceClickHandler}
                            onClickSubscribe={(device) => {
                                subscribe(checkedUser.principalId, device.uuid);
                            }}
                            deviceInfoDisplayProps={deviceInfoDisplayProps}
                    toggleModalHandler={toggleModalHandler}
                        />
                    ) : (
                        ""
                    )}
                </AddDeviceModal>
            </form>
        </>
    );
};

export default AddDeviceContainer;
