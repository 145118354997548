import {
    MDBAlert,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBContainer,
} from 'mdb-react-ui-kit';

import { useAppDispatch } from 'app/hooks';
import logo from 'assets/img/raonix_logo.png';
import { Auth } from 'aws-amplify';
import CountryCodeInputs from 'components/fragment/countryCodeInput';
import NormalInputSection from 'components/fragment/normalInputSection';
import { PrevBtn } from 'components/fragment/prevBtn';
import {
    RegisterInputListProps,
    registerInputs,
} from 'constants/registerInputs';
import { updateUsername } from 'features/signup-slice';
import { useEffect, useState } from 'react';
import { CountryData } from 'react-intl-tel-input';
import { useNavigate } from 'react-router-dom';
import addressFind from 'utils/addressFind';
import { useTranslation } from 'react-i18next';

interface RegisterProps {
    email?: string;
    username: string;
    password: string;
    passwordConfirm?: string;
    name: string;
    phone_number: string;
    address: string;
    addressDetail?: string;
}
const loginUINew = true;

const RegisterContainer = (props) => {
    /* from react-route */
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(["trans"]);

    /* from redux */
    const dispatch = useAppDispatch();

    /* props */
    const { readTerms } = props;

    /* local state */
    const [registerInputList, setRegisterInputList] = useState<
        RegisterInputListProps[]
    >([...registerInputs]);
    const [activateRegisterButton, setActivateRegisterButton] =
        useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);


    // input의 change event 가 발생될 때마다, validation check를 하고, 만약 validation이 true일 경우 버튼을 activate 함
    useEffect(() => {
        const find = registerInputList.find((input) => {
            return input.hasOwnProperty('validation') && !input.validation;
        });

        find
            ? setActivateRegisterButton(false)
            : setActivateRegisterButton(true);
    }, [registerInputList]);

    useEffect(() => {
        if (!errorMessage) return;

        setShowError(true);
    }, [errorMessage]);

    useEffect(() => {
        if (showError) return;
        setErrorMessage('');
    }, [showError]);

    // 비밀번호 validation
    function validatePassword(
        password: string,
        passwordProp: RegisterInputListProps
    ) {
        if (passwordProp.name !== 'password') return;

        const strongRegex = new RegExp(
            '^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
        );

        if (!password.match(strongRegex)) {
            passwordProp.message =
                '비밀번호는 반드시 8자 이상, 특수문자, 번호, 알파벳이 포함되어야 합니다.';
            passwordProp.validation = false;
        } else {
            passwordProp.message = '';
            passwordProp.validation = true;
        }
    }

    // 비밀번호 재입력 validation
    function validatePasswordConfirm(
        password: string,
        passwordConfirm: string,
        passwordConfirmProp: RegisterInputListProps
    ) {
        if (passwordConfirmProp.name !== 'passwordConfirm') return;

        if (password === passwordConfirm) {
            passwordConfirmProp.message = '';
            passwordConfirmProp.validation = true;
        } else {
            passwordConfirmProp.message = '비밀번호가 일치하지 않습니다.';
            passwordConfirmProp.validation = false;
        }
    }

    function validatePhoneNumber(
        isValid: boolean,
        phoneNumberProp: RegisterInputListProps
    ) {
        phoneNumberProp.validation = isValid;

        isValid
            ? (phoneNumberProp.message = '')
            : (phoneNumberProp.message = `It's not proper format.`);
    }

    async function registerSubmitHandler(
        event: React.FormEvent<HTMLFormElement>
    ) {
        event.preventDefault();

        if (!activateRegisterButton) return;

        const signupProps = registerInputList.reduce((acc, cur) => {
            acc[cur.name] = cur.value;

            return acc;
        }, {}) as RegisterProps;

        const {
            username,
            password,
            email,
            name,
            phone_number,
            address,
            addressDetail,
        } = signupProps;

        const signupResult = await Auth.signUp({
            username,
            password,
            attributes: {
                email,
                name,
                phone_number,
                address: address + addressDetail,
            },
        }).catch((error) => {
            console.info(error);
            setErrorMessage(error.messages || error);
        });

        if (signupResult) {
            dispatch(updateUsername(signupProps.username));
            navigate('/confirm-signup');
        }

        /* signup이 성공하면 send code page로 이동 */
    }

    function inputChangeHandler(event) {
        const input = event?.target;
        const inputList = [...registerInputList];

        const findIndex = inputList.findIndex(
            (inputProp) => inputProp.name === input.name
        );
        const find = {
            ...registerInputList[findIndex],
        };

        find.value = input.value;
        find.validation = !!input.value;

        /* password 관련 */
        const passwordFind = inputList.find(
            (inputProp) => inputProp.name === 'password'
        );

        validatePassword(input.value, find);
        validatePasswordConfirm(passwordFind.value, input.value, find);
        /* password 관련 */

        inputList[findIndex] = find;
        setRegisterInputList(inputList);
    }

    function countryCodeChangeHandler(
        isValid: boolean,
        value: string,
        selectedCountryData: CountryData,
        fullNumber
    ) {
        const inputList = [...registerInputList];

        const findIndex = inputList.findIndex(
            (inputProp) => inputProp.name === 'phone_number'
        );
        const find = { ...inputList[findIndex] };

        find.value = `${fullNumber.replaceAll('-', '').replace(' ', '')}`;
        validatePhoneNumber(isValid, find);

        inputList[findIndex] = find;
        setRegisterInputList(inputList);
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    const inputs = registerInputList?.map((inputElement, index) => {
        const key = inputElement.id + index;

        if (inputElement.name === 'phone_number') {
            return (
                <CountryCodeInputs
                    key={key}
                    horizontal={true}
                    label={inputElement.label}
                    inputChange={countryCodeChangeHandler}
                    message={inputElement.message}
                    darkModeEnable={false}
                ></CountryCodeInputs>
            );
        }

        return (
            <NormalInputSection
                key={key}
                horizontal={true}
                type={inputElement.type}
                id={inputElement.id}
                label={inputElement.label}
                placeholder={inputElement.placeholder}
                required={inputElement.required}
                name={inputElement.name}
                inputChange={inputChangeHandler}
                inputClick={(event) => {
                    if (inputElement.addressFind) {
                        addressFind(event);
                    }
                }}
                focusOut={inputChangeHandler}
                message={inputElement.message}
                darkModeEnable={false}
            />
        );
    });

    return (
        <>
            <MDBAlert color="warning" position="top-center" className="w-100" offset={0} appendToBody show={showError}>
                {errorMessage}
                <MDBBtn className="btn-close" noRipple outline color="none" onClick={closeErrorHandler} />
            </MDBAlert>
            {loginUINew ? (
                <MDBContainer>
                    <MDBCard className="login-reset-card">
                        <form onSubmit={registerSubmitHandler}>
                            <MDBCardHeader className="login-reset-header">
                                <div
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <PrevBtn /> {t("LOCAD0030", "뒤로")}
                                </div>
                                <div className="login-reset-header-title">
                                    {t("LOCAD0063","LOCAD0063-계정생성")}
                                    </div>
                            </MDBCardHeader>
                            <MDBCardBody className='login-reset-body'>{inputs}</MDBCardBody>
                            <MDBCardFooter className="login-reset-footer">
                                <span></span>
                                <button type="submit" className="reset-submit-button" disabled={!activateRegisterButton}>
                                    {t("LOCAD0064","LOCAD0064-등록")}
                                </button>
                            </MDBCardFooter>
                        </form>
                    </MDBCard>
                </MDBContainer>
            ) : (
                <MDBContainer>
                    <MDBCard className="normal-card">
                        <form onSubmit={registerSubmitHandler}>
                            <MDBCardHeader className="d-flex justify-content-between">
                                <div
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <PrevBtn /> 
                                    {t("LOCAD0063","LOCAD0063-계정생성")}
                                </div>
                                <img src={logo} alt="raonix_logo" height="40" loading="lazy" className="me-2" />
                            </MDBCardHeader>
                            <MDBCardBody>{inputs}</MDBCardBody>
                            <MDBCardFooter className="d-flex justify-content-between align-items-center">
                                {/* <div className='form-check'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id='readTermsCheck'
                                disabled={!readTerms ?? false}
                            />
                            <label
                                className='form-check-label'
                                htmlFor='readTermsCheck'
                            >
                                I have read and agree to the{' '}
                                <NavLink to='/policy'>Terms of Service</NavLink>
                            </label>
                        </div> */}
                                <span></span>
                                <button type="submit" className="btn btn-primary" disabled={!activateRegisterButton}>
                                    {t("LOCAD0064","LOCAD0064-등록")}
                                </button>
                            </MDBCardFooter>
                        </form>
                    </MDBCard>
                </MDBContainer>
            )}
        </>
    );
};

export default RegisterContainer;
