export const TRAIT_TYPE = {
    LEVEL_SENSOR: "levelSensor",
    BINARY_SENSOR: "binarySensor"
}

export const SIDENAV_WIDTH = "350px";
export const COLOR_SIDE_NAV_BG = "#2c2c2c";
export const COLOR_MAIN_BG = "#202020";
export const COLOR_SIDE_NAV_BUTTON_BG = "#191919";

export const SYMBOL = {
    TEMP : "°C",
    PERCENT : "%",
    HUMI : "%",
    DUST : "ug/m³",
    CO2 : "ppm",
    VOLT: "V",

}