import { DeviceParams } from 'api/device';
import DeviceAddTable from 'components/fragment/deviceAddTable';
import { DeviceinfoDisplayProps } from 'containers/location/addDeviceContainer';

export interface AddDeviceListDisplayProps {
    deviceInfoList: DeviceParams[];
    searchResult?: any;
    addClick?: (event) => void;
    onClickSubscribe?: (event) => void;
    deviceInfoDisplayProps?: DeviceinfoDisplayProps;
    toggleModalHandler?: any;
}

const AddDeviceListDisplay = (props: AddDeviceListDisplayProps) => {
    const { toggleModalHandler, deviceInfoList, addClick, onClickSubscribe, deviceInfoDisplayProps } = props;

    return (
        <>
            <DeviceAddTable
                body={deviceInfoList}
                addClick={addClick}
                onClickSubscribe={onClickSubscribe}
                deviceInfoDisplayProps={deviceInfoDisplayProps}
                toggleModalHandler={toggleModalHandler}
            />
        </>
    );
};

export default AddDeviceListDisplay;
