import React, { useEffect, useState } from 'react';
import { MDBAlert, MDBBtn } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

export interface StackingAlertContainerProps {
    name?: string;
    stackingCount?: number;
    message?: string;
}

const StackingAlertContainer = (props: StackingAlertContainerProps) => {
    const { stackingCount, name, message } = props;

    const { t, i18n } = useTranslation(['trans']);

    const [stackingAlerts, setStackingAlerts] = useState<any>([]);
    const [stackingColor, setStackingColor] = useState(0);

    const colors = [
        'primary',
        'warning',
        'info',
        'success',
        'secondary',
        'danger',
        'light',
    ];

    const handleStackingClick = () => {
        stackingColor + 1 > colors.length - 1
            ? setStackingColor(0)
            : setStackingColor(stackingColor + 1);

        const ID = Math.random().toString();

        setStackingAlerts([
            ...stackingAlerts,
            {
                color: colors[stackingColor],
                count:
                    stackingAlerts.length > 0 ? stackingAlerts.length + 1 : 1,
                visible: true,
                id: ID,
                name,
            },
        ]);
    };

    useEffect(() => {
        if (!stackingCount || stackingCount <= 0) return;

        handleStackingClick();
    }, [stackingCount]);

    const handleCloseStackingClick = (number) => {
        const tempStackingAlerts = [];
        stackingAlerts.forEach((alert) => {
            if (alert.id === number) {
                tempStackingAlerts.push({
                    color: alert.color,
                    count: alert.count,
                    visible: false,
                    id: alert.id,
                });

                return;
            }

            tempStackingAlerts.push(alert);
        });

        setStackingAlerts(tempStackingAlerts);
    };

    return (
        <>
            <MDBBtn className='d-none' onClick={handleStackingClick}>
                Show notification
            </MDBBtn>
            {stackingAlerts.map((item) => {
                return (
                    <MDBAlert
                        className="d-flex justify-content-between"
                        color={item.color}
                        stacking
                        autohide
                        width={450}
                        position="bottom-right"
                        offset={10}
                        delay={3000}
                        appendToBody
                        show={item.visible}
                        key={item.id}
                    >
                        <p className="mb-0">
                            {message ||
                                //`${item?.name && item?.name.toUpperCase()} ${t("LOCAL0003", " has been Changed.")}`}
                                `${item?.name && item?.name.toUpperCase()}`}
                        </p>
                        <MDBBtn
                            className="btn-close"
                            noRipple
                            outline
                            color="none"
                            onClick={() => handleCloseStackingClick(item.id)}
                        />
                    </MDBAlert>
                );
            })}
        </>
    );
};

export default StackingAlertContainer;
