import { useEffect, useState } from "react";
import { DeviceParams } from "api/device";
import { ReportParams } from "api/report";
import ThermometerTemperatureHumidity from "components/fragment/charts/ThermometerTemperatureHumidity";
import DateSearchContainer from "containers/dateSearchContainer";
import { PrevBtn } from "components/fragment/prevBtn";
import ReportSecondTable from "components/fragment/reportSecondTable";
import _ from "lodash";
import {
    MDBContainer,
    MDBSpinner,
} from "mdb-react-ui-kit";
import { getStatistics } from "utils/report";
import { useTranslation } from "react-i18next";
//import CsvDownload from "components/fragment/csvDownload";

interface ReportDisplayProps {
    iconClick?: (param: string) => void;
    currentDevice: DeviceParams;
    reportSearchSubmit: (event, query?) => void;
    reportList?: ReportParams[];
    reportListItemClick?;
    report: ReportParams;
    toggleModalHandler: () => void;
    csvDownloadClickHandler?: (event?) => boolean;
    csvFileName?: string;
    isLoading?: boolean;
}

const ReportDisplay = (props: ReportDisplayProps) => {
    /* from i18n */
    const { t, i18n } = useTranslation(["trans"]);

    const [confirmModal, setConfirmModal] = useState(false);

    const toggleShow = () => setConfirmModal(!confirmModal);

    const { currentDevice, reportSearchSubmit, report, toggleModalHandler, csvDownloadClickHandler, csvFileName, isLoading } =
        props;
    const { title, description, traits, records, startDate, endDate } = report;

    /**
     * 최대 최소 평균 구하기.
     */
    let atraits = Object.keys(traits)
        .map((uuid) => {
            return { uuid, label: traits[uuid].label };
        })
        .sort((a, b) => {
            const la = a.label;
            const lb = b.label;
            return la < lb ? -1 : la > lb ? 1 : 0;
        });

    const stats = atraits.reduce((acc, cur) => {
        if (records[cur.uuid]) {
            const stat = getStatistics(records[cur.uuid], startDate, endDate);
            if (!_.isEmpty(stat)) {
                acc.push({ label: cur.label, ...stat[0] });
            }
        }

        return acc;
    }, []);

    return (
        <MDBContainer
                fluid
                className="subpage-container pt-2 px-lg-5"
                style={{ overflowX: 'auto' }} // 가로 스크롤을 활성화
            >
            {/**
             * API response pending: loading bar add
             */
             isLoading && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // 반투명한 배경
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999, // 최상위 레이어로 보이도록 설정
                    }}
                >
                    <MDBSpinner role="status">
                        <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                </div>
            )}

            <div className="label-header">
                <div className="d-flex align-items-center">
                    {/* <div className="mx-3">
                        {<PrevBtn />}
                    </div> */}
                    <div>
                        <h2 className="m-0">{/* <h2 className="m-3"> */}
                            {t("LOCAD0012", "보고서")} {" | "}
                        </h2>
                    </div>
                    {currentDevice && (
                        <div className="alarm-device-info text-nowrap text-grayish">
                            <div>
                                <small className="me-3">
                                    {t("LOCAD0023", "시리얼번호")}: {currentDevice?.deviceInfo.serial}
                                </small>
                            </div>
                            <div>
                                <small className="me-3">
                                    {t("LOCAD0024", "장치이름")}: {currentDevice?.nickname}
                                </small>
                            </div>
                        </div>
                    )}
                </div>
                <div className="ml-auto">
                    {currentDevice && <DateSearchContainer searchSubmit={reportSearchSubmit} />}
                </div>
            </div>

            <div className="table-box">
                <ThermometerTemperatureHumidity report={report} />
            </div>
            <div className="table-box">
                <ReportSecondTable body={stats} />
            </div>
            {/**
            <div
                style={{
                    display: "inline-block",
                    position: "fixed",
                    zIndex: 5000,
                    bottom: 10,
                    right: 10,
                }}
            >
                <MDBBtn
                    floating
                    tag="a"
                    //onClick={toggleModalHandler}
                    onClick={toggleShow}
                >
                    <MDBIcon fas icon="download" />
                </MDBBtn>
            </div>
            <MDBModal animationDirection="top" show={confirmModal} tabIndex="-1" setShow={setConfirmModal}>
                <MDBModalDialog position="top" frame>
                    <MDBModalContent>
                        <MDBModalBody className="py-1">
                            <div className="d-flex justify-content-center align-items-center my-3">
                                <p className="mb-0">{t("LOCAL0002", "LOCAL0002-리포트를 저장하시겠습니까?")}</p>
                                <MDBBtn
                                    color="primary"
                                    size="sm"
                                    className="ms-2"
                                    onClick={() => {
                                        toggleShow();
                                        toggleModalHandler();
                                    }}
                                >
                                    {t("LOCAD0001", "LOCAD0001-네")}
                                </MDBBtn>
                                <MDBBtn color="info" size="sm" className="ms-2" onClick={toggleShow}>
                                    {t("LOCAD0002", "LOCAD0002-아니오")}
                                </MDBBtn>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
                 */}
        </MDBContainer>
    );
};

export default ReportDisplay;
