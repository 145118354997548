import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBContainer,
    MDBIcon,
    MDBInputGroup,
} from 'mdb-react-ui-kit';
import { NavLink, useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'app/hooks';
import logo from 'assets/img/raonix_logo.png';
import { Auth, Logger } from 'aws-amplify';
import { withFederated } from 'aws-amplify-react';
import Spinner from 'components/fragment/spinner';
import { updateUsername } from 'features/signup-slice';
import { changeAuthState, updateUserInfo } from 'features/user-slice';
import { useState } from 'react';
import styled from '@emotion/styled';

const FederatedButtons = (props) => (
    <MDBBtn
        className='m-1'
        style={{ backgroundColor: '#dd4b39' }}
        href='#'
        onClick={props.googleSignIn}
    >
        <MDBIcon fab icon='google me-2' />
        Sign in using Google
    </MDBBtn>
);

const Federated = withFederated(FederatedButtons);

const federated_data = {
    google_client_id:
        '242142492941-g68i7euee6uil3fq84dk49bm84d3snrl.apps.googleusercontent.com',
};

const logger = new Logger('SignIn');

interface UserInputs {
    username: string;
    password: string;
}

const loginUINew = true;
const StyledLoginContainer = styled(MDBContainer)`
`;

const StyledLoginCard = styled(MDBCard)`
    max-width: 500px;
    margin: var(--topbar-height) auto;
    background-color: transparent;
    box-shadow: none;
`;
const LoginTitleBig = styled.span`
    font-size: 4rem;
    color: white;
    font-weight: 900;
`;
const SignInButton = styled.button`
  width: 100%; /* input과 동일한 너비 */
  height: 40px; /* input과 동일한 높이 */
  border: 1px solid #cccccc; /* 테두리도 input과 동일하게 설정 */
  background-color: #ffffff; /* 배경색 흰색 */
  color: #202020; /* 글자 색상 #202020 */
  font-weight: bold; /* 글자 진하게 */
  text-transform: uppercase; /* 대문자 변환 */
  border-radius: 0px; /* input과 동일한 네모 반듯한 테두리 */
  font-size: 1rem; /* 글자 크기 */
  cursor: pointer; /* 버튼 커서 */
  transition: background-color 0.3s ease; /* 배경색 전환 효과 */

  &:hover {
    background-color: #f0f0f0; /* 호버 시 약간의 배경색 변화 */
  }

  &:focus {
    outline: none; /* 포커스 시 기본 outline 제거 */
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const StyledLink = styled(NavLink)`
  color: #ccc; /* 회색 글자색 */
  text-decoration: none;
  font-weight: bold;
  padding: 0 10px;

  &:hover {
    color: #495057; /* 호버 시 약간 더 진한 회색 */
  }
`;

const Divider = styled.span`
  color: #6c757d; /* 구분자 색상도 회색 */
  padding: 0 10px;
`;

const PhsLogin = (props) => {
    /* from react-router */
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const [userInputs, setUserInputs] = useState<UserInputs>();
    const [errorState, setErrorState] = useState<{
        error: string;
        alert: boolean;
    }>({ error: '', alert: false });
    const [loader, setLoader] = useState<boolean>(false);

    // 현재 유저가 접속해 있는 지 아닌 지 판별
    function checkContact(user) {
        Auth.verifiedContact(user)
            .then((data) => {
                if (data.verified) {
                    dispatch(updateUserInfo(data));
                } else {
                    const userInfo = { ...user, ...data };
                    dispatch(updateUserInfo(userInfo));
                }
            })
            .catch((error) => console.log(error));
    }

    // 접속 성공 시
    async function handleSuccess(user) {
        logger.info('sign in success', user);
        checkContact(user);
    }

    // 접속 실패 시
    function handleError(err) {
        logger.info('sign in error:', err);
        /*
          err can be in different structure:
            1) plain text message;
            2) object { code: ..., message: ..., name: ... }
        */
        setErrorState({
            error: err.message || err,
            alert: true,
        });
        if (err.code && err.code === 'UserNotConfirmedException') {
            //auth state에 confirmSignUp(현재 로그인되어 있지 않음)을 전달
            dispatch(changeAuthState('confirmSignUp'));
            dispatch(updateUsername(userInputs.username));
            navigate('/confirm-signup');
        }
    }

    // 접속 성공 시(signIn - handleSuccess - checkContact 순으로 호출)
    async function signIn(event) {
        event.preventDefault();

        setLoader(true);

        const duplicatedUserInputs: UserInputs = { ...userInputs };

        await Auth.signIn(
            duplicatedUserInputs.username || '',
            duplicatedUserInputs.password
        )
            .then((user) => handleSuccess(user))
            .catch((err) => handleError(err));

        setLoader(false);
    }

    function inputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        const inputName = event.target.name;
        const inputValue = event.target.value;

        if (inputName === 'email') {
            setUserInputs({ ...userInputs, username: inputValue });
        } else if (inputName === 'password') {
            setUserInputs({ ...userInputs, password: inputValue });
        }
    }

    let errorContainer: JSX.Element;

    if (errorState.error) {
        errorContainer = (
            <div className='text-danger text-center mb-2'>
                <small>{errorState.error}</small>
            </div>
        );
    }

    const spinner = (
        <div className='text-center text-primary mb-2'>
            <Spinner size='sm' />
        </div>
    );

    return (
        <>
            {loginUINew === true ? (
                <StyledLoginContainer
                    className="login-card"
                    style={
                        {
                         //border: "1px solid red",
                         whiteSpace: "nowrap",
                        }
                    }
                >
                    <StyledLoginCard>
                        <MDBCardBody>
                            <MDBCardTitle className="text-center">
                                <LoginTitleBig>NEMOAHN</LoginTitleBig>
                            </MDBCardTitle>
                            <MDBCardBody>
                                <form onSubmit={signIn}>
                                    <div>
                                        <div className="login-input-section">
                                            <MDBInputGroup>
                                                <input
                                                    className="form-control"
                                                    name="email"
                                                    type="email"
                                                    id="login-email"
                                                    placeholder="email@email.com"
                                                    onChange={inputHandler}
                                                    required
                                                />
                                            </MDBInputGroup>
                                        </div>
                                        <div className="login-input-section">
                                            <MDBInputGroup>
                                                <input
                                                    className="form-control"
                                                    name="password"
                                                    type="password"
                                                    id="login-password"
                                                    placeholder="password"
                                                    onChange={inputHandler}
                                                    required
                                                />
                                            </MDBInputGroup>
                                        </div>
                                        {loader ? spinner : errorContainer}
                                        <div className="text-center">
                                            <SignInButton type="submit">sign-in</SignInButton>
                                        </div>
                                    </div>
                                    {/* 링크 버튼을 중앙 정렬 */}
                                    <LinkContainer>
                                        <StyledLink to="/reset-password">Reset password</StyledLink>
                                        <Divider>|</Divider>
                                        <StyledLink to="/register">Register Account</StyledLink>
                                    </LinkContainer>
                                </form>
                            </MDBCardBody>
                        </MDBCardBody>
                    </StyledLoginCard>
                </StyledLoginContainer>
            ) : (
                <MDBContainer className="login-card">
                    <MDBCard className="phs-login">
                        <MDBCardBody>
                            <MDBCardTitle className="text-center">
                                <img src={logo} alt="raonix_logo" height="80" />
                            </MDBCardTitle>
                            <p className="text-center">Sign-In</p>
                            <MDBCardBody>
                                <form onSubmit={signIn}>
                                    <div>
                                        <div className="input-section">
                                            <label className="form-label" htmlFor="login-email">
                                                Email
                                            </label>
                                            <MDBInputGroup>
                                                <input
                                                    className="form-control"
                                                    name="email"
                                                    type="email"
                                                    id="login-email"
                                                    placeholder="email@email.com"
                                                    onChange={inputHandler}
                                                    required
                                                />
                                            </MDBInputGroup>
                                        </div>
                                        <div className="input-section">
                                            <label className="form-label" htmlFor="login-password">
                                                Password
                                            </label>
                                            <MDBInputGroup>
                                                <input
                                                    className="form-control"
                                                    name="password"
                                                    type="password"
                                                    id="login-password"
                                                    placeholder="password"
                                                    onChange={inputHandler}
                                                    required
                                                />
                                            </MDBInputGroup>
                                        </div>
                                        {loader ? spinner : errorContainer}
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary">
                                                sign-in
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <small className="form-text">
                                            <NavLink to="/reset-password">Forgot your password? Reset password</NavLink>
                                        </small>
                                    </div>
                                    <div className="mt-3">
                                        <small className="form-text">
                                            <NavLink to="/register">Register Account</NavLink>
                                        </small>
                                    </div>
                                    <div className="border my-2"></div>
                                </form>
                            </MDBCardBody>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            )}
        </>
    );
};

export default PhsLogin;
