import { TablePagination } from "@mui/material";
import { useAppSelector } from "app/hooks";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DeviceParams, queryDeviceSubscribe, queryGetPlace, queryPutPlace } from "api/device";
import { DeviceinfoDisplayProps } from "containers/location/addDeviceContainer";
import { useCallback, useEffect, useState } from "react";
import Spinner from "./spinner";
import { MDBBtn } from "mdb-react-ui-kit";
import NormalInputSection from "./normalInputSection";
import { Application } from "constants/application";
import { useTranslation } from "react-i18next";

interface TableFragProps {
    head?: string[];
    body: Object[] | DeviceParams[];
    numberOfRows?: number;
    fullHeight?: boolean;
    addClick?: (event) => void;
    onClickSubscribe?: (event) => void;
    deviceInfoDisplayProps?: DeviceinfoDisplayProps;
    toggleModalHandler?: any;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#292929" /* '#1266f1' */,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },

    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },

    "& th:not(th:last-child), & td:not(td:last-child)": {
        // borderRight: '2px lightgrey solid',
    },
}));

interface PlaceMapping {
  uuid: string;
  placeUuid: string;
};

const tableColumnPlaceViewEnable = true;
const DeviceAddTable = (props: TableFragProps) => {
    const { t } = useTranslation(["trans"]);
    const { toggleModalHandler, body, fullHeight, addClick, onClickSubscribe, deviceInfoDisplayProps }: TableFragProps =
        props;
    const numberOfRows = props.numberOfRows || 10;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(numberOfRows);

    const checkedUser = useAppSelector((state) => state.authState.checkedUser);

    const currentLocationId = useAppSelector((state) => state.authState.currentLocationId);
    const placeList = useAppSelector((state) => state.display.placeList);
    const [placeData, setPlaceData] = useState<Application[]>([]);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const [rows, setRows] = useState<JSX.Element[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [placeMappings, setPlaceMappings] = useState<PlaceMapping[]>([]); // uuid-placeUuid 매핑 배열

    useEffect(() => {
        console.log("placeMapping change", placeMappings);
    }, [placeMappings]);

    useEffect(() => {
        console.log("currentLocationId", currentLocationId);
    }, [currentLocationId]);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDisplay = useCallback(() => {
        if (window.matchMedia("(max-width: 600px)").matches) {
            setRowsPerPage(10);
        } else {
            setRowsPerPage(numberOfRows);
        }
    }, []);

    useEffect(() => {
        const array = Object.keys(placeList).map((index) => {
            const place = placeList[index];
            return { value: place?.uuid, text: place?.nickname };
        });
        //console.log("array", array);
        setPlaceData(array);
    }, [placeList]);

    useEffect(() => {
        handleDisplay();

        window.addEventListener("resize", handleDisplay);
    }, []);

    const selectValueChangeHandler = (event) => {
        const value = event.target.value;
        console.log("selectValueChangeHandler", value);
    };

    const buttonRender = (row: any) => {
        const disabled = deviceInfoDisplayProps[row.uuid]?.added;
        let button = (
            <button
                type="button"
                className={disabled ? "btn btn-sm btn-danger text-white" : "btn btn-sm btn-primary"}
                onClick={() => addClick(row.uuid)}
                disabled={disabled}
            >
                {disabled ? "added" : "add"}
            </button>
        );

        if (deviceInfoDisplayProps[row.uuid]?.loading) {
            button = (
                <button type="button" className="btn btn-sm btn-primary" disabled>
                    <Spinner size="sm" />
                </button>
            );
        }

        return (
            <>
                {row.subscribed ? (
                    <> {button} </>
                ) : (
                    <MDBBtn
                        className="btn-secondary"
                        size="sm"
                        onClick={(e: { preventDefault: () => void }) => {
                            //e.preventDefault();
                            onClickSubscribe(row);
                        }}
                    >
                        subscribe
                    </MDBBtn>
                )}
            </>
        );
    };

    const fetchRows = async (currentMappings: { uuid: string; placeUuid: string }[]) => {
        if (!body) {
            setRows([
                <StyledTableRow key="no-search">
                    <StyledTableCell colSpan={100} height="400" className="text-center">
                        <p>게이트웨이를 먼저 검색하세요.</p>
                    </StyledTableCell>
                </StyledTableRow>,
            ]);
            return;
        }

        if (body.length <= 0) {
            setRows([
                <StyledTableRow key="no-device">
                    <StyledTableCell colSpan={100} height="400" className="text-center">
                        <p>장치가 없습니다.</p>
                    </StyledTableCell>
                </StyledTableRow>,
            ]);
            return;
        }

        const slicedBody = body.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        const rowElements = await Promise.all(
            slicedBody.map(async (row: DeviceParams) => {
                let placeUuid = currentMappings.find((mapping) => mapping.uuid === row.uuid)?.placeUuid || "";

                if (!placeUuid) {
                    try {
                        const response = await queryGetPlace(checkedUser.principalId, row.uuid);
                        placeUuid = response.data?.placeUuid || "";

                        // state 업데이트 없이 local mappings 사용
                        currentMappings = [
                            ...currentMappings.filter((mapping) => mapping.uuid !== row.uuid),
                            { uuid: row.uuid, placeUuid },
                        ];
                    } catch (error) {
                        console.info(error);
                    }
                }

                return (
                    <StyledTableRow key={row.uuid}>
                        <StyledTableCell align="center">{row.nickname}</StyledTableCell>
                        <StyledTableCell align="center">{row.deviceInfo.model}</StyledTableCell>
                        <StyledTableCell align="center">{row.deviceInfo.serial}</StyledTableCell>
                        <StyledTableCell align="center">{row.deviceInfo.manufacturer}</StyledTableCell>
                        {tableColumnPlaceViewEnable ? (
                            <StyledTableCell align="center" sx={{ width: 100 }}>
                                <NormalInputSection
                                    id="place"
                                    type="select"
                                    from="deviceAddTable"
                                    horizontal={true}
                                    name="locationSelect"
                                    label="장소"
                                    data={placeData}
                                    value={
                                        currentMappings.find((mapping) => mapping.uuid === row.uuid)?.placeUuid || ""
                                    }
                                    valueChanged={(newValue) => {
                                        console.log("change newValue", newValue.target.value);

                                        setPlaceMappings((prev) => {
                                            // 해당 uuid가 이미 존재하는지 확인
                                            const existingMapping = prev.find((mapping) => mapping.uuid === row.uuid);

                                            if (existingMapping) {
                                                // 기존 uuid에 대해 placeUuid 업데이트
                                                return prev.map((mapping) =>
                                                    mapping.uuid === row.uuid
                                                        ? { ...mapping, placeUuid: newValue.target.value }
                                                        : mapping
                                                );
                                            } else {
                                                // prev가 비어 있거나, 해당 uuid가 없는 경우 새 항목 추가
                                                return [...prev, { uuid: row.uuid, placeUuid: newValue.target.value }];
                                            }
                                        });
                                    }}
                                    darkModeEnable={true}
                                />
                            </StyledTableCell>
                        ) : (
                            <>
                                <StyledTableCell align="center">{row.deviceInfo.hwVersion}</StyledTableCell>
                                <StyledTableCell align="center">{row.deviceInfo.swVersion}</StyledTableCell>
                                <StyledTableCell align="center" sx={{ width: 100 }}>
                                    <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                                        {buttonRender(row)}
                                    </div>
                                </StyledTableCell>
                            </>
                        )}
                    </StyledTableRow>
                );
            })
        );

        setRows(rowElements);
    };

    // useEffect 분리: placeMappings 변경 시 상태 초기화 없이 새로고침
    useEffect(() => {
        setLoading(true);
        fetchRows(placeMappings).finally(() => setLoading(false));
    }, [body, page, rowsPerPage, checkedUser, queryGetPlace, tableColumnPlaceViewEnable]);

    // placeMappings 변경 시 별도 useEffect로 처리
    useEffect(() => {
        setLoading(true);
        fetchRows(placeMappings).finally(() => setLoading(false));
    }, [placeMappings]);

    return (
        <div className="mt-3">
            <TableContainer
                component={Paper}
                sx={{
                    borderRadius: "0",
                    boxShadow: "none",
                    maxHeight: fullHeight ? "100%" : "500px",
                }}
            >
                <Table stickyHeader sx={{ minWidth: 900, height: "100%" }} aria-label="customized table" size="small">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">이름{/* Name */}</StyledTableCell>
                            <StyledTableCell align="center">모델명{/* MODEL */}</StyledTableCell>
                            <StyledTableCell align="center">시리얼 번호{/* Serial */}</StyledTableCell>
                            <StyledTableCell align="center">제조사{/* Manufacturer */}</StyledTableCell>
                            {tableColumnPlaceViewEnable ? (
                                <>
                                    <StyledTableCell align="center">장소</StyledTableCell>
                                </>
                            ) : (
                                <>
                                    <StyledTableCell align="center">하드웨어{/* HW */}</StyledTableCell>
                                    <StyledTableCell align="center">펌웨어{/* Firmware */}</StyledTableCell>
                                    <StyledTableCell align="center">구독 설정</StyledTableCell>
                                </>
                            )}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
            {!body ? (
                ""
            ) : (
                <TablePagination
                    sx={{ width: "100%" }}
                    rowsPerPageOptions={[]}
                    component="div"
                    count={body.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={false}
                />
            )}
            <div
                style={{
                    width: "100%", // 부모 넓이의 100%
                    display: "flex",
                    justifyContent: "flex-end", // 우측 정렬
                }}
            >
                <button
                    type="button"
                    className="btn btn-primary button"
                    style={{
                        width: "90px",
                        backgroundColor: "#292929",
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "200",
                        borderRadius: "4px",
                        boxShadow: "none",
                        padding: "0.4rem 1.4rem",
                    }}
                    onClick={async () => {
                        try {
                            const promises = placeMappings.map(({ uuid, placeUuid }) =>
                                queryPutPlace(checkedUser.principalId, uuid, { placeUuid }).catch((error) => {
                                    console.info(`Fail to change device place for UUID: ${uuid}`);
                                    console.info(error);
                                })
                            );

                            // 모든 작업 완료 대기
                            await Promise.all(promises);
                        } catch (error) {
                            console.error("An unexpected error occurred during saveMappings.", error);
                        }

                        toggleModalHandler();
                    }}
                >
                    {t("", "저장")}
                </button>
            </div>
        </div>
    );
};

export default DeviceAddTable;
