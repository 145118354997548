import {
    queryDeleteReport,
    queryGetReports,
    queryPostReport,
    queryReportRecords,
    ReportParams,
} from 'api/report';
import { useAppSelector } from 'app/hooks';
import ReportDisplay from 'components/display/reportDisplay';
import { DateSearchQuery } from 'containers/dateSearchContainer';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTraitByName } from 'utils/report';
import PreviewPdfContainer from './previewPdfContainer';
import { useTranslation } from 'react-i18next';
import config from 'config';

const needTrait = ['temperature', 'humidity'];

const WRITERS = {
    thermometer: 'ThermometerTemperatureHumidity',
    heatcable: 'HeatcableTemperature',
};

const ReportContainer = (props) => {
    const { t } = useTranslation(["trans"]);
    /* from redux */
    const navigate = useNavigate();

    /* from redux */
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);
    const currentDevice = useAppSelector((state) => state.display.currentDevice);

    /* display state */
    const [report, setReport] = useState<ReportParams>({
        description: "",
        deviceType: "",
        startDate: "",
        endDate: "",
        title: "",
        traits: {},
        records: {},
        writer: "",
    });
    const [isLoading, setIsLoading] = useState(true);

    const [reportList, setReportList] = useState<{
        [prop: string]: ReportParams;
    }>();
    const [tempReport, setTempReport] = useState<ReportParams>();
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (!currentDevice) {
            navigate(-1);
        }
    }, [currentDevice, navigate]);

    async function getAllReports() {
        const reportGetResult = await queryGetReports(checkedUser.principalId).catch((error) => console.info(error));

        return reportGetResult.data;
    }

    /**
     * 모든 report를 조회함
     * 임시
     */
    useEffect(() => {
        async function run() {
            const result = await getAllReports();

            //console.log("getAllReports result", result);
            const find = result && {
                ...result["1dec9529-6878-4938-b6e4-05845a12356e"],
                uuid: "1dec9529-6878-4938-b6e4-05845a12356e",
            };

            setReportList(result);
            //setTempReport(find);
            setTempReport(result);
        }

        checkedUser?.principalId && run();
    }, [checkedUser, report]);

    function addTraitToTraits(traits, trait, nickname, label) {
        traits[trait.uuid] = {
            name: trait.name,
            //label: nickname + " " + label,
            label: label,       //No26. 장치이름제거
        };
    }

    /*
    // 자동으로 불러오게 하면 이코드 쓰면 되는데 phs 센서들때문에 그렇게는 안된다. 그냥 수동으로 해야됨.
    function getReportTraits(modelCode) {
        const traits = {};
        // currentDevice의 모델에 대한 alarmFilter에 있는 항목들을 사용해 traits를 가져오는 부분

        const currentModel = config.models[currentDevice.modelCode]; // currentDevice 모델에 맞는 필터 가져오기

        if (currentModel) {
            // alarmFilter에 있는 모든 trait 이름에 대해 getTraitByName을 호출
            currentModel.alarmFilter.forEach((traitName) => {
                const trait = getTraitByName(currentDevice, traitName);
                if (trait) {
                    console.log("find trait", trait);
                    addTraitToTraits(traits, trait, `${currentDevice.nickname} ${traitName}`);
                }
            });
        } else {
            console.error(`Model ${currentDevice.modelCode} not found in models`);
        }

        return traits;
    }
        */
    /**
     * report Params에 맞게 traits를 변형시킴
     */
    useEffect(() => {
        if (!currentDevice) return;

        //console.log("currentDevice", currentDevice);

        //const traits = getReportTraits(currentDevice?.modelCode);
        const traits = {};
        switch (currentDevice?.modelCode) {
            case "r9iot-ssphs":
                const ssphs_temp = getTraitByName(currentDevice, "temperature");
                const ssphs_humi = getTraitByName(currentDevice, "humidity");
                //console.log("ssphs_temp trait", ssphs_temp);
                //console.log("ssphs_humi trait", ssphs_humi);
                addTraitToTraits(traits, ssphs_temp, currentDevice.nickname, "온도");
                addTraitToTraits(traits, ssphs_humi, currentDevice.nickname, "습도");

                const sensor1 = getTraitByName(currentDevice, "sensor1");
                const sensor2 = getTraitByName(currentDevice, "sensor2");
                const sensor3 = getTraitByName(currentDevice, "sensor3");
                const sensor4 = getTraitByName(currentDevice, "sensor4");
                switch (currentDevice?.attributes?.device_type) {
                    case 1:
                        addTraitToTraits(traits, sensor1, currentDevice.nickname, "미세먼지 PM1.0");
                        addTraitToTraits(traits, sensor2, currentDevice.nickname, "미세먼지 PM2.5");
                        addTraitToTraits(traits, sensor3, currentDevice.nickname, "미세먼지 PM10.0");
                        break;
                    case 2:
                        addTraitToTraits(traits, sensor1, currentDevice.nickname, "PH");
                        break;
                    case 3:
                        addTraitToTraits(traits, sensor1, currentDevice.nickname, "CO2");
                        break;
                    case 4:
                        addTraitToTraits(traits, sensor1, currentDevice.nickname, "NH3");
                        break;
                    case 5:
                        addTraitToTraits(traits, sensor1, currentDevice.nickname, "토양수분");
                        break;
                    case 6:
                        addTraitToTraits(traits, sensor1, currentDevice.nickname, t("LOCAD0005","외부온도")+"1");
                        addTraitToTraits(traits, sensor2, currentDevice.nickname, t("LOCAD0005","외부온도")+"2");
                        break;
                    default:
                        break;
                }

                break;
            case "r9iot-thermometer":
                const temp = getTraitByName(currentDevice, "temperature");
                const humi = getTraitByName(currentDevice, "humidity");
                //console.log("temp trait", temp);
                //console.log("humi trait", humi);

                addTraitToTraits(traits, temp, currentDevice.nickname, "온도");
                addTraitToTraits(traits, humi, currentDevice.nickname, "습도");

                break;
            case "r9iot-heatcable":
                const on1 = getTraitByName(currentDevice, "on1");
                const on2 = getTraitByName(currentDevice, "on2");
                const curtemp1 = getTraitByName(currentDevice, "curtemp1");
                const curtemp2 = getTraitByName(currentDevice, "curtemp2");
                const ext_curtemp = getTraitByName(currentDevice, "ext_curtemp");

                addTraitToTraits(traits, on1, currentDevice.nickname, "배관1");
                addTraitToTraits(traits, on2, currentDevice.nickname, "배관2");
                addTraitToTraits(traits, curtemp1, currentDevice.nickname, "배관1온도");
                addTraitToTraits(traits, curtemp2, currentDevice.nickname, "배관2온도");
                addTraitToTraits(traits, ext_curtemp, currentDevice.nickname, "외기온도");
                break;
            default:
                break;
        }
        setReport({
            ...report,
            traits: traits,
            deviceType: currentDevice?.type,
            writer: WRITERS[currentDevice.type],
        });
    }, [currentDevice]);

    async function deleteReport(principalId: string, reportUuid: string) {
        //모든 report를 먼저 조회함

        const deleteReport = await queryDeleteReport(principalId, reportUuid).catch((error) => console.info(error));

        return deleteReport || null;
    }

    /**
     * 같은 traituuid, startdate, enddate를 포함하고 있으면 이전에 post된 report를 return하는 함수
     * @param reportList
     * @param reportParams
     * @returns
     */
    function findSameReport(reportList: { [prop: string]: ReportParams }, reportParams: ReportParams) {
        const find = Object.keys(reportList).find((key) => {
            const keyItem = reportList[key];

            return (
                _.isEqual(Object.keys(keyItem.traits), Object.keys(reportParams.traits)) &&
                keyItem.startDate === reportParams.startDate &&
                keyItem.endDate === reportParams.endDate
            );
        });

        return find;
    }

    /* handler start */
    async function reportSearchSubmitHandler(event, query: DateSearchQuery) {
        if (event) {
            // 최초 조회시 event 없이 실행됨
            event.preventDefault();
        }

        //console.log("report", report);
        const newParams: ReportParams = {
            ...report,
            records: null,
            title: currentDevice.nickname,
            startDate: query.start,
            endDate: query.end,
        };
        //console.log("newParams", newParams);

        // report의 날짜와 현재 검색하려는 날짜가 같을 경우 submit하지않음
        if (report.startDate === newParams.startDate && report.endDate === newParams.endDate) {
            return;
        }

        setIsLoading(true);

        const reportPostResult = await queryPostReport(checkedUser.principalId, newParams).catch((error) =>
            console.info(error)
        );

        if (!reportPostResult)  {
            setIsLoading(false);
            return;
        }

        const returnReport = reportPostResult.data;

        // report list 에 같은 startDate, endDate, device-trait uuid가 포함된 report가 존재 할 경우, 이전에 존재하던 report를 삭제하고 새로 검색한 report를 display
        const findKey = findSameReport(reportList, returnReport);

        findKey && (await deleteReport(checkedUser.principalId, findKey));

        const recordsResult = await queryReportRecords(checkedUser.principalId, reportPostResult.data).catch((error) =>
            console.info(error)
        );

        setReport({
            ...report,
            records: { ...recordsResult },
            startDate: query.start,
            endDate: query.end,
        });
        setIsLoading(false);
    }

    function toggleModalHandler() {
        setShowModal(!showModal);
    }

    // 현재 구현되지 않은 기능
    function csvDownloadClickHandler(event) {
        if (window.confirm("Download csv file?")) {
            return true;
        }

        return false;
    }

    const csvFileName = "report_" + dayjs().format();

    /* handler end */

    const renderReportDisplay = useMemo(() => {
        if (!reportList) return;
        return (
            <ReportDisplay
                currentDevice={currentDevice}
                reportSearchSubmit={reportSearchSubmitHandler}
                report={report}
                toggleModalHandler={toggleModalHandler}
                csvDownloadClickHandler={csvDownloadClickHandler}
                csvFileName={csvFileName}
                // reportListItemClick={reportListItemClickHandler}
                isLoading={isLoading}
            ></ReportDisplay>
        );
    }, [reportList, currentDevice, report, toggleModalHandler, csvFileName]);

    return (
        <>
            {renderReportDisplay}
            <PreviewPdfContainer
                preview={tempReport}
                showModal={showModal}
                setShowModal={setShowModal}
                toggleModalHandler={toggleModalHandler}
            />
        </>
    );
};

export default ReportContainer;
