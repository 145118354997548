// @ts-nocheck
import React, { useMemo } from "react";
import dayjs from 'dayjs';
import { getEventDatasets } from 'utils/report';
import { chartColors, rgb2rgba } from 'utils/utils';
import ThermometerChart from './ThermometerChart';

/*
const interpolate = (timestamps, sensorData) => {
    const sortedData = [...sensorData].sort((a, b) => new Date(a.x) - new Date(b.x));

    return timestamps.map((timestamp) => {
        const time = new Date(timestamp).getTime();
        let before = null,
            after = null;

        for (const point of sortedData) {
            const pointTime = new Date(point.x).getTime();
            if (pointTime <= time) before = point;
            if (pointTime > time) {
                after = point;
                break;
            }
        }

        if (before && after) {
            const beforeTime = new Date(before.x).getTime();
            const afterTime = new Date(after.x).getTime();
            const ratio = (time - beforeTime) / (afterTime - beforeTime);
            return { x: timestamp, y: before.y + ratio * (after.y - before.y) };
        }

        return { x: timestamp, y: before ? before.y : after ? after.y : null };
    });
};
*/
// 성능개선
const interpolate = (timestamps, sensorData) => {
    const sortedData = [...sensorData].sort((a, b) => new Date(a.x) - new Date(b.x));

    return timestamps.map((timestamp) => {
        const time = new Date(timestamp).getTime();

        let left = 0, right = sortedData.length - 1;
        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            if (new Date(sortedData[mid].x).getTime() === time) {
                return { x: timestamp, y: sortedData[mid].y }; // 값이 정확히 일치할 경우
            } else if (new Date(sortedData[mid].x).getTime() < time) {
                left = mid + 1;
            } else {
                right = mid - 1;
            }
        }

        const before = sortedData[right] || null;
        const after = sortedData[left] || null;

        if (before && after) {
            const beforeTime = new Date(before.x).getTime();
            const afterTime = new Date(after.x).getTime();
            const ratio = (time - beforeTime) / (afterTime - beforeTime);
            return { x: timestamp, y: before.y + ratio * (after.y - before.y) };
        }

        return { x: timestamp, y: before ? before.y : after ? after.y : null };
    });
};

const Chart = ({ report }) => {
    const processedData = useMemo(() => {
        if (!report) return null;

        const datasets = getEventDatasets(report);
        if (!datasets) return null;

        //const allTimestamps = [...new Set(datasets.flatMap((sensor) => sensor.data.map((point) => point.x)))].sort();
        // 성능개선
        const allTimestamps = [];
        datasets.forEach((sensor) => {
            sensor.data.forEach((point) => {
                if (!allTimestamps.includes(point.x)) {
                    allTimestamps.push(point.x);
                }
            });
        });
        allTimestamps.sort();

        return datasets.map((sensor) => ({
            name: sensor.name,
            label: sensor.label,
            data: interpolate(allTimestamps, sensor.data),
        }));
    }, [report]);

    const labels = useMemo(() => {
        return processedData[0]?.data.map((label) => dayjs(label.x).format("M/D, HH:mm:ss"));
    }, [processedData]);

    const chartData = useMemo(() => {
        return {
            labels,
            datasets: processedData.map((e, index) => {
                const keys = Object.keys(chartColors);
                const key = keys[index];
                const color = chartColors[key];
                const type = e.label.includes("온도");
                const yAxis = type ? "temperature" : "humidity";

                return {
                    type: type ? "line" : "bar",
                    fill: false,
                    lineTension: 0,
                    borderWidth: 2,
                    borderColor: color,
                    backgroundColor: rgb2rgba(color, 0.5),
                    label: e.label,
                    data: e.data,
                    yAxisId: yAxis,
                    xAxisId: "date",
                };
            }),
        };
    }, [labels, processedData]);

    if (!processedData) return null;

    return <ThermometerChart data={chartData} type="ThermometerTemperatureHumidity" />;
};

export default Chart;
